/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useNavigationType, useParams } from 'react-router-dom'

import { UilCheckCircle, UilExclamationTriangle } from '@iconscout/react-unicons'
import { gaEvents } from '~/events'
import dayjs from 'dayjs'

import { useAuth } from '~/_context/Auth'
import { IUserProps } from '~/_context/Auth/Auth.interfaces'

import { useServiceUpdateProjectStatus } from '~/services/Lesson'
import { useTrackSchedule } from '~/services/Track'
import { useServiceUpdateTrail } from '~/services/TrailEdit'
import { useAvailableClassesToSchedule, useServiceEngageTrail } from '~/services/TrailSchedule'
import { useServiceDesengageTrail } from '~/services/TrailsVacate'

import { validateType } from '~/validations/setTypeModal'

import { ISchedulePageProviderProps } from '../../ScheduleByWeek/interfaces'
import { IScheduleContextProps, MomentDates } from './interfaces'

const ScheduleTrailPageContext = createContext<IScheduleContextProps>({
  dataTrackSchedule: undefined,
  dates: [],
  projectType: '',
  setDates: () => {},
  datesFormatted: [],
  validateMoment: () => false,
  disabledDate: () => false,
  loadingRequestSchedule: false,
  actionSchedule: undefined,
  confirmModal: { isOpen: false, message: '' },
  handleCloseConfirm: () => {},
  openModalSucess: false,
  confirmModalDesengage: { isOpen: false, message: '' },
  handleCloseConfirmDesengage: () => {},
  handleDesengageTrail: () => {},
  handleCloseSuccess: () => {},
  handleConfirm: () => {},
  actionDesengage: undefined,
  loadingDesengage: false,
  canDesengage: false,
  handleDateChange: () => {},
  handleDateChangeSchedule: () => {},
  classInfo: undefined,
  title: '',
  loadingUpdate: false,
  handleLeave: () => {},
  setOpenModalEtapa: () => {},
  openModalEtapa: false,
  handleDesengageEtapa: () => {},
  handleCloseDesengageEtapa: () => {},
  openModalRealize: false,
  handleRealize: () => {},
  handleCloseRealize: () => {},
  dateRealize: undefined,
  handleConfirmRealize: () => {},
  loadingRealize: false,
  loadingGet: false,
  canSchedule: false,
  handleBack: () => {},
})
dayjs().locale('pt-br')

const ScheduleTrailProvider = ({ children }: ISchedulePageProviderProps) => {
  const { classId, trackId } = useParams()
  const navigate = useNavigate()
  const navigationType = useNavigationType()

  const {
    data: dataTrackSchedule,
    isLoading: loadingGet,
    refetch,
    remove,
  } = useTrackSchedule(Number(classId), Number(trackId), !!classId && !!trackId)
  const [confirmModal, setConfirmModal] = useState<boolean>(false)
  const [confirmModalDesengage, setConfirmModalDesengage] = useState<boolean>(false)
  const [openModalSucess, setOpenModalSucess] = useState<boolean>(false)
  const [openModalEtapa, setOpenModalEtapa] = useState<boolean>(false)
  const [openModalRealize, setOpenModalRealize] = useState<boolean>(false)
  const [dateRealize, setDateRealize] = useState<string>('')
  const [idMoment, setIdMoment] = useState<number>(0)
  const [lastNuOrder, setLastNuOrder] = useState<number>(0)
  const [save, setSave] = useState<boolean>(false)
  const [changedIndexes, setChangedIndexes] = useState<number[]>([])
  const [dates, setDates] = useState<MomentDates[]>([])
  const { user } = useAuth()
  const trackStageInfo = dataTrackSchedule?.trackStageInfo
  const title = dataTrackSchedule?.txTrackTitle
  const classInfo = dataTrackSchedule?.class
  const canDesengage = !!(dataTrackSchedule?.moments?.length && dataTrackSchedule.moments.length > 0)
  const projectType = dates?.every((date) => date?.project === 1)
  const isEditing = dates?.every((date) => date?.type === 'edit')
  const [manualSuccess, setManualSuccess] = useState(false)
  const [haveDesengage, setHaveDesengage] = useState(false)
  const [changedOtherClass, setChangedOtherClass] = useState(false)
  const [showRemark, setShowRemark] = useState(false)
  const [showNuOrder, setShowNuOrder] = useState(0)
  const [openModalConfirmOtherClass, setOpenModalConfirmOtherClass] = useState(false)
  const [showConfirmOtherClass, setShowConfirmOtherClass] = useState(false)
  const [remarkPast, setRemarkPast] = useState<boolean>(false)

  useEffect(() => {
    removeAvailableClassesToSchedule()
    remove()
    setManualSuccess(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleBack = useCallback(() => {
    const previousPath = sessionStorage.getItem('previousPath')
    const hasDtSchedule = dates.some((date) => date?.dtSchedule)

    if (navigationType === 'PUSH' && hasDtSchedule && previousPath !== location.pathname && !haveDesengage) {
      navigate(-1)
    } else {
      navigate(`/class-details/${classId}`)
    }
  }, [dates, navigationType, haveDesengage, navigate, classId])

  const { mutate: handleChangeStatus, isLoading: loadingRealize } = useServiceUpdateProjectStatus(
    'AVPE',
    Number(idMoment),
    Number(classId),
    {
      onSuccess: () => {
        let toastId: string | undefined

        if (loadingRequestSchedule) {
          toastId = toast.loading('Carregando...')
        }
        {
          toast.success('Aula alterada com sucesso!', {
            id: toastId,
            icon: <UilCheckCircle color="#fff" size="24" />,
            style: {
              background: '#0BB07B',
              color: '#fff',
            },
          })
        }
        setRemarkPast(false)
        refetch()
      },
      onError: (error) => {
        let toastId: string | undefined

        if (loadingRequestSchedule) {
          toastId = toast.loading('Carregando...')
        }
        toast.error(error?.response.data?.message || 'Ocorreu um Erro', {
          id: toastId,
          icon: <UilExclamationTriangle color="#fff" size={40} />,
          style: {
            background: '#F03D3D',
            color: '#fff',
          },
        })
      },
    },
  )

  useEffect(() => {
    if (dataTrackSchedule && dataTrackSchedule?.moments.length > 0) {
      let agenCount = 0

      const schedules = trackStageInfo?.map((stage) => {
        const matchingMoment = dataTrackSchedule?.moments?.find((moment) => moment.nuMomentOrder === stage.nuOrder)

        if (matchingMoment) {
          if (matchingMoment.coMomentStatus === 'AGEN' && matchingMoment.dtSchedule) {
            agenCount++

            const newScheduleDate =
              agenCount === 1
                ? dayjs().add(7, 'days').format('YYYY-MM-DD')
                : dayjs()
                    .add(7 * agenCount, 'days')
                    .format('YYYY-MM-DD')

            return {
              idTrackGroup: stage.idTrackGroup,
              nuOrder: stage.nuOrder,
              txTitle: stage.txTitle,
              type: 'edit',
              project: validateType(dataTrackSchedule.coStage),
              coStage: dataTrackSchedule.coStage,
              idMoment: matchingMoment.idMoment,
              coMomentStatus: matchingMoment.coMomentStatus,
              dtSchedule: newScheduleDate,
              professor: matchingMoment.professor,
            }
          }

          return {
            idTrackGroup: stage.idTrackGroup,
            nuOrder: stage.nuOrder,
            txTitle: stage.txTitle,
            type: 'edit',
            project: validateType(dataTrackSchedule.coStage),
            coStage: dataTrackSchedule.coStage,
            idMoment: matchingMoment.idMoment,
            coMomentStatus: matchingMoment.coMomentStatus,
            dtSchedule: matchingMoment.professor ? matchingMoment.dtSchedule : null,
            professor: matchingMoment.professor,
          }
        }
      })

      setDates(schedules)
    } else {
      const schedules = trackStageInfo?.map((stage) => ({
        ...stage,
        type: 'schedule',
        dtSchedule: stage.dtSchedule ?? null,
        project: validateType(dataTrackSchedule?.coStage),
        coStage: dataTrackSchedule.coStage,
      }))

      setDates(schedules)
    }
  }, [trackStageInfo, dataTrackSchedule?.moments, dataTrackSchedule])

  const {
    mutate: handleSchedule,
    error: errorRequestSchedule,
    isLoading: loadingRequestSchedule,
    data: dataScheduleRequest,
  } = useServiceEngageTrail()

  useEffect(() => {
    setManualSuccess(false)
    refetch().then()
  }, [classId, refetch, trackId])

  const {
    data: dataAvailableClassesToSchedule,
    remove: removeAvailableClassesToSchedule,
    isLoading: loadingAvailableClassesToSchedule,
    error: errorAvailableClassesToSchedule,
  } = useAvailableClassesToSchedule(Number(trackId), String(classInfo?.coGrade), manualSuccess)

  useEffect(() => {
    const firstProductGroup = ['IN', 'F1']
    if (!loadingAvailableClassesToSchedule) {
      if (errorAvailableClassesToSchedule) {
        navigate(`/class-details/${classId}`)
      }
      if (!errorAvailableClassesToSchedule && dataAvailableClassesToSchedule && manualSuccess) {
        if (dataAvailableClassesToSchedule.length < 1) {
          if (dataTrackSchedule && firstProductGroup.includes(dataTrackSchedule.coStage)) {
            return navigate(`/trail/${trackId}/occupied/${classId}`)
          }

          return navigate(`/trail/${trackId}/project/${classId}`)
        } else {
          sessionStorage.setItem('@LEKTO:actionAvailableToSchedule', `true`)
          if (dataTrackSchedule && firstProductGroup.includes(dataTrackSchedule.coStage)) {
            return navigate(`/trail/${trackId}/occupied/${classId}`)
          }

          return navigate(`/trail/${trackId}/project/${classId}`)
        }
      }
    }
  }, [
    loadingAvailableClassesToSchedule,
    manualSuccess,
    errorAvailableClassesToSchedule,
    dataAvailableClassesToSchedule,
    classId,
    removeAvailableClassesToSchedule,
    navigate,
    dataTrackSchedule,
    trackId,
  ])

  useEffect(() => {
    let toastId: string | undefined

    if (loadingRequestSchedule) {
      toastId = toast.loading('Carregando...')
    }

    if (!loadingRequestSchedule) {
      if (errorRequestSchedule) {
        toast.error(errorRequestSchedule?.response?.data?.message || 'Ocorreu um Erro', {
          id: toastId,
          style: {
            background: '#F03D3D',
            color: '#fff',
          },
        })
      } else if (!errorRequestSchedule && dataScheduleRequest) {
        toast.success('Trilha salva com sucesso!', {
          id: toastId,
          icon: <UilCheckCircle color="#fff" size="24" />,
          style: {
            background: '#0BB07B',
            color: '#fff',
          },
        })
      }
    }
    return () => {
      if (toastId) {
        toast.dismiss(toastId)
      }
    }
  }, [loadingRequestSchedule, errorRequestSchedule, dataScheduleRequest, navigate, classId])

  const {
    mutate: handleUpdate,
    error: errorUpdate,
    isLoading: loadingUpdate,
    data: dataUpdate,
  } = useServiceUpdateTrail()

  const {
    mutate: handleUpdateRemark,
    error: errorUpdateRemark,
    isLoading: loadingUpdateRemark,
    data: dataUpdateRemark,
  } = useServiceUpdateTrail()

  useEffect(() => {
    if (!loadingUpdateRemark) {
      if (!errorUpdateRemark && dataUpdateRemark) {
        handleChangeStatus()
      }
    }
  }, [dataUpdateRemark, errorUpdateRemark, handleChangeStatus, loadingUpdateRemark])

  useEffect(() => {
    let toastId: string | undefined

    if (loadingUpdate) {
      toastId = toast.loading('Carregando...')
    }

    if (!loadingUpdate && !loadingRealize) {
      if (errorUpdate) {
        toast.error(errorUpdate.response.data?.message || 'Ocorreu um Erro', {
          id: toastId,
          style: {
            background: '#F03D3D',
            color: '#fff',
          },
        })
      } else if (!errorUpdate && dataUpdate) {
        toast.success('Trilha salva com sucesso!', {
          id: toastId,
          icon: <UilCheckCircle color="#fff" size="24" />,
          style: {
            background: '#0BB07B',
            color: '#fff',
          },
        })
        handleBack()
      }
    }
    return () => {
      if (toastId) {
        toast.dismiss(toastId)
      }
    }
  }, [dataUpdate, errorUpdate, handleBack, loadingRealize, loadingUpdate])

  const {
    mutate: handleDesengage,
    error: errorDesengage,
    isLoading: loadingDesengage,
    data: dataDesengage,
  } = useServiceDesengageTrail()

  useEffect(() => {
    let toastId: string | undefined

    if (!loadingDesengage) {
      if (errorDesengage) {
        toast.error(errorDesengage.response.data?.message || 'Ocorreu um Erro', {
          id: toastId,
          style: {
            background: '#F03D3D',
            color: '#fff',
          },
        })
      } else if (!errorDesengage && dataDesengage) {
        toast.success('Trilha desocupada com sucesso!', {
          id: toastId,
          icon: <UilCheckCircle color="#fff" size="24" />,
          style: {
            background: '#0BB07B',
            color: '#fff',
          },
        })
        navigate(`/class-details/${classId}`)
      }
    }
    return () => {
      if (toastId) {
        toast.dismiss(toastId)
      }
    }
  }, [
    loadingRequestSchedule,
    errorRequestSchedule,
    dataScheduleRequest,
    loadingDesengage,
    errorDesengage,
    dataDesengage,
    navigate,
    classId,
  ])

  const handleConfirm = useCallback(() => {
    setConfirmModal(true)
  }, [setConfirmModal])

  const handleCloseConfirm = useCallback(() => {
    setConfirmModal(false)
  }, [setConfirmModal])

  const handleCloseSuccess = useCallback(() => {
    setOpenModalSucess(false)
  }, [])

  const actionSchedule = useCallback(() => {
    const createDataRequest = () => ({
      moments: dates?.map((item) => ({
        idTrackGroup: item.idTrackGroup,
        idMoment: item.idMoment,
        type: item.type,
        idUserProfessor: item?.professor?.idUser ?? item?.idUserProfessor,
        nuOrder: item.nuOrder,
        ...(item.dtSchedule && { dtSchedule: item.dtSchedule }),
      })),
    })
    const processDataRequest = (dataRequest) => {
      const isEditing = dataRequest.moments?.every((item: MomentDates) => item.type === 'edit')

      if (isEditing) {
        handleUpdate({ body: dataRequest, classId: Number(classId), lessonId: Number(trackId) })
      } else {
        handleSchedule(
          { body: dataRequest, classId: Number(classId), lessonId: Number(trackId) },
          {
            onSuccess: () => {
              setManualSuccess(true)
            },
          },
        )
      }
    }
    if (!changedOtherClass && !showConfirmOtherClass) {
      const dataRequest = createDataRequest()
      processDataRequest(dataRequest)
      return
    }
    if (changedOtherClass && !showConfirmOtherClass) {
      setShowConfirmOtherClass(true)
      setOpenModalConfirmOtherClass(true)
      return
    }
    if (changedOtherClass && showConfirmOtherClass) {
      const dataRequest = createDataRequest()
      processDataRequest(dataRequest)
      return
    }
    // eslint-disable-next-line
  }, [changedOtherClass, showConfirmOtherClass, dates, handleUpdate, classId, trackId, handleSchedule])

  const actionRemark = useCallback(() => {
    const createDataRequest = () => ({
      moments: dates?.map((item) => ({
        idTrackGroup: item.idTrackGroup,
        idMoment: item.idMoment,
        type: item.type,
        idUserProfessor: item?.professor?.idUser ?? item?.idUserProfessor,
        nuOrder: item.nuOrder,
        dtSchedule: item.dtSchedule,
      })),
    })
    if (showRemark) {
      handleUpdateRemark({ body: createDataRequest(), classId: Number(classId), lessonId: Number(trackId) })
    }

    // eslint-disable-next-line
  }, [dates, showRemark])

  const disabledDate = useCallback(
    (date: dayjs.Dayjs, order: number) => {
      const numDates = dates?.length
      const today = dayjs()
      const yesterday = dayjs().subtract(1, 'day')
      const dateList = Array.from({ length: numDates }, (_, i) =>
        dayjs(dates[i].dtSchedule).startOf('day').format('YYYY-MM-DD'),
      )

      // Filtrar e criar a lista com apenas as datas válidas
      const validDates = Object.entries(dateList)
        .filter(([key, value]) => dayjs(value).isValid())
        .map(([key, value]) => ({ order: parseInt(key) + 1, date: dayjs(value).startOf('day') }))

      // Encontra a última data válida antes do 'order' atual
      const lastValidDate = validDates
        .filter((d) => d.order < order)
        .reduce((prev, curr) => (curr.order > prev.order ? curr : prev), { order: 0, date: dayjs(null) })

      // Encontra a próxima data válida após o 'order' atual (se existir)
      const nextValidDate = validDates.find((d) => d.order > order)

      // Caso não tenha nenhuma data válida anterior e nem próxima
      if (!lastValidDate.date.isValid() && !nextValidDate) {
        return false
      }

      // Caso a data esteja antes da última data válida ou depois da próxima
      if (lastValidDate.date.isValid() && date.isBefore(lastValidDate.date)) {
        return true
      }

      if (date.isAfter(today) && showRemark) {
        return true
      }
      if (date.isBefore(yesterday) && !showRemark) {
        return true
      }

      return false
    },
    [dates, showRemark],
  )

  const validateMoment = useCallback(
    (moment: MomentDates): boolean => {
      if (['edit'].includes(moment.type)) {
        const condition2 =
          moment?.professor &&
          moment?.professor?.idUser !== user?.id_user &&
          moment?.professor?.idUserProfessor !== user?.id_user

        const condition3 = ['FINA', 'AVPE'].includes(moment?.coMomentStatus)

        return condition2 || condition3
      }

      return false
    },
    [user?.id_user],
  )
  const datesFormatted = useMemo(() => {
    return dates?.map((date) => {
      const dtSchedule = date?.dtSchedule
      const disabled = validateMoment(date)
      return { dtSchedule, disabled }
    })
  }, [dates, validateMoment])

  const handleDesengageTrail = useCallback(() => {
    setHaveDesengage(true)
    setConfirmModalDesengage(true)
  }, [setConfirmModalDesengage])

  const handleCloseConfirmDesengage = useCallback(() => {
    setConfirmModalDesengage(false)
  }, [setConfirmModalDesengage])

  const handleCloseRealize = useCallback(() => {
    setOpenModalRealize(false)
    setShowRemark(false)
  }, [])

  const handleCloseConfirmOtherClass = useCallback(() => {
    setOpenModalRealize(false)
    setOpenModalConfirmOtherClass(false)
  }, [setOpenModalRealize])

  const validateSchedule = useCallback(() => {
    if (!dates || dates.length === 0) return false
    if (isEditing) return true

    if (projectType) {
      return dates.every((date) => !!date.dtSchedule)
    } else {
      return dates.some((date) => !!date.dtSchedule)
    }
  }, [dates, isEditing, projectType])

  const canSchedule = validateSchedule()
  const handleDateChangeSchedule = useCallback(
    (index: number, newDate: string) => {
      setDates((prevDates) => {
        const newDates = [...prevDates]
        const formattedDate = dayjs(newDate).format('YYYY-MM-DD')
        let otherClassChanged = false

        if (projectType) {
          gaEvents.eventInputCalendar()

          // Verifica se a primeira aula foi remarcada
          if (index === 0) {
            for (let i = 1; i <= 11; i++) {
              if (newDates[index + i] && !newDates[index + i].dtSchedule) {
                const currentDate = dayjs(newDate)
                const newDateIndex = currentDate.add(7 * i, 'days').format('YYYY-MM-DD')
                newDates[index + i].dtSchedule = newDateIndex
                newDates[index + i].idUserProfessor = user?.id_user
              }
            }
          }
        } else {
          // Verifica se a aula remarcada já tem uma data
          if (newDates[index] && !newDates[index].dtSchedule) {
            const currentDate = dayjs(newDate).format('YYYY-MM-DD')
            newDates[index].dtSchedule = currentDate
            newDates[index].idUserProfessor = user?.id_user
          }
        }

        // Atualiza a data da aula remarcada
        newDates[index].dtSchedule = formattedDate
        newDates[index].idUserProfessor = user?.id_user

        // Verifica se a data da aula remarcada ultrapassa a próxima aula
        for (let i = index + 1; i < newDates.length; i++) {
          const nextDate = newDates[i].dtSchedule

          // Se a nova data da aula atual for após a data da próxima aula
          if (dayjs(formattedDate).isAfter(dayjs(nextDate))) {
            // Reorganiza as datas das aulas subsequentes
            for (let j = i; j < newDates.length; j++) {
              // Verifica se a aula é de outro professor
              if (
                (newDates[i]?.professor?.idUser ||
                  newDates[i]?.professor?.idUserProfessor ||
                  newDates[i]?.idUser ||
                  newDates[i]?.idUserProfessor) !== user?.id_user
              ) {
                otherClassChanged = true
              }

              if (newDates[j].idUserProfessor) {
                newDates[j].dtSchedule = dayjs(newDates[index].dtSchedule)
                  .add(7 * j, 'days')
                  .format('YYYY-MM-DD')
              }
            }
            break
          }
        }

        // Seta true para changedOtherClass apenas se uma aula de outro professor foi alterada
        if (otherClassChanged) {
          setChangedOtherClass(true)
        }

        return newDates
      })
      setChangedIndexes((prevIndexes) => [...prevIndexes, index])
    },
    [projectType, user?.id_user],
  )

  const handleDesengageEtapa = useCallback(
    (nuOrder: number) => {
      setLastNuOrder(nuOrder)
      setOpenModalEtapa(true)
    },
    [setOpenModalEtapa],
  )

  const handleCloseDesengageEtapa = useCallback(() => {
    setOpenModalEtapa(false)
  }, [setOpenModalEtapa])

  const actionDesengage = useCallback(() => {
    if (projectType) {
      handleCloseConfirmDesengage()
      handleDesengage({ classId: Number(classId), lessonId: Number(trackId) })
    } else {
      const loggedInProfessorId = user?.id_user

      const allLessonsMatch = dates.every((date) => {
        const professorMatch = date.professor
          ? date.professor.idUser === loggedInProfessorId || date.idUserProfessor === loggedInProfessorId
          : true

        const statusMatch =
          date.coMomentStatus !== 'FINA' && date.coMomentStatus !== 'AVPE' && date.coMomentStatus !== 'CANC'

        return professorMatch && statusMatch
      })

      if (allLessonsMatch) {
        handleDesengage({ classId: Number(classId), lessonId: Number(trackId) })
      } else {
        setDates((prevDates) => {
          const updatedDates = prevDates.map((date) => {
            if (
              (!date?.professor ||
                date.professor.idUser === loggedInProfessorId ||
                date.idUserProfessor === loggedInProfessorId) &&
              date.coMomentStatus !== 'FINA' &&
              date.coMomentStatus !== 'AVPE' &&
              date.coMomentStatus !== 'CANC'
            ) {
              const { dtSchedule, professor, idUserProfessor, coMomentStatus, ...rest } = date
              return { ...rest, dtSchedule: null }
            }
            return date
          })
          return updatedDates
        })
        handleCloseConfirmDesengage()
        setSave(true)
      }
    }
  }, [projectType, handleCloseConfirmDesengage, handleDesengage, classId, trackId, user?.id_user, dates])

  useEffect(() => {
    if (save && loadingDesengage) {
      actionSchedule()
    }
  }, [actionSchedule, loadingDesengage, save])

  const handleLeave = useCallback(() => {
    setDates((prevDates) => {
      const updatedDates = prevDates.map((date) => {
        if (date.nuOrder === lastNuOrder) {
          return {
            ...date,
            dtSchedule: null,
            idUserProfessor: undefined,
            coMomentStatus: undefined,
            professor: undefined,
          }
        }
        return date
      })
      setLastNuOrder(0)
      handleCloseDesengageEtapa()
      return updatedDates
    })
  }, [setDates, lastNuOrder, handleCloseDesengageEtapa])

  const handleRealize = useCallback(
    (nuOrder: number, date: string) => {
      setLastNuOrder(nuOrder)
      setDateRealize(date)
      setOpenModalRealize(true)
    },
    [setOpenModalRealize],
  )

  const handleConfirmRealize = useCallback(() => {
    setDates((prevDates) => {
      const matchedDate = prevDates.find((date) => date.nuOrder === lastNuOrder)
      if (matchedDate && matchedDate.idMoment) {
        setIdMoment(matchedDate.idMoment)
        handleChangeStatus()
        setOpenModalRealize(false)
      }
      return prevDates
    })
  }, [handleChangeStatus, lastNuOrder, setDates])

  const handleConfirmRemark = useCallback(() => {
    if (openModalRealize && !showRemark) {
      setShowNuOrder(lastNuOrder)
      setRemarkPast(true)
      setShowRemark(true)
      setOpenModalRealize(false)
    }
  }, [lastNuOrder, openModalRealize, showRemark])

  const updateDates = (
    index: number,
    newDate: string,
    dates: MomentDates[],
    user: IUserProps | null,
    setChangedOtherClass: (value: boolean) => void,
  ) => {
    const newDates = [...dates]
    const stageIndex = dates?.length || 0
    const currentDate = dayjs(newDate).format('YYYY-MM-DD')
    let otherClassChanged = false

    // Atualiza a data da aula atual
    newDates[index].dtSchedule = currentDate

    // Se o id do professor está definido, atualiza a propriedade idUserProfessor
    if (user?.id_user !== undefined) {
      newDates[index].idUserProfessor = user?.id_user
    }

    let lastUpdatedDate = currentDate
    for (let i = index + 1; i < stageIndex; i++) {
      const nextDate = newDates[i].dtSchedule

      if (nextDate && dayjs(lastUpdatedDate).isAfter(dayjs(nextDate))) {
        if (
          (newDates[i]?.professor?.idUser ||
            newDates[i]?.professor?.idUserProfessor ||
            newDates[i]?.idUser ||
            newDates[i]?.idUserProfessor) !== user?.id_user
        ) {
          otherClassChanged = true
        }

        // Atualiza a data subsequente para 7 dias a partir da última data ajustada
        lastUpdatedDate = dayjs(lastUpdatedDate).add(7, 'days').format('YYYY-MM-DD')
        newDates[i].dtSchedule = lastUpdatedDate
      } else if (!nextDate) {
        continue
      } else {
        break
      }
    }

    // Seta true para changedOtherClass apenas se uma aula de outro professor foi alterada
    if (otherClassChanged) {
      setChangedOtherClass(true)
    }

    return newDates
  }

  const handleDateChange = useCallback(
    ({ index, newDate }: { index: number; newDate: string }) => {
      const newDates = updateDates(index, newDate, dates, user, setChangedOtherClass)
      setDates(newDates)
      gaEvents.eventInputCalendar()
      setChangedIndexes((prevIndexes) => [...prevIndexes, index])
    },
    [dates, user],
  )

  const handleDateRemark = useCallback(
    ({ index, newDate }: { index: number; newDate: string }) => {
      const newDates = updateDates(index, newDate, dates, user, setChangedOtherClass)
      setDates(newDates)
      gaEvents.eventInputCalendar()
      setChangedIndexes((prevIndexes) => [...prevIndexes, index])
      setIdMoment(newDates[index]?.idMoment)
      actionRemark()
    },
    [actionRemark, dates, user],
  )

  const scheduleTrailPageProviderValues = useMemo(() => {
    return {
      dataTrackSchedule,
      dates,
      projectType,
      setDates,
      datesFormatted,
      validateMoment,
      disabledDate,
      loadingRequestSchedule,
      actionSchedule,
      confirmModal,
      handleCloseConfirm,
      openModalSucess,
      confirmModalDesengage,
      handleCloseConfirmDesengage,
      handleDesengageTrail,
      handleCloseSuccess,
      handleConfirm,
      actionDesengage,
      loadingDesengage,
      canDesengage,
      handleDateChange,
      handleDateChangeSchedule,
      classInfo,
      title,
      loadingUpdate,
      handleLeave,
      setOpenModalEtapa,
      openModalEtapa,
      handleDesengageEtapa,
      handleCloseDesengageEtapa,
      openModalRealize,
      handleRealize,
      handleCloseRealize,
      handleCloseConfirmOtherClass,
      dateRealize,
      handleConfirmRealize,
      handleConfirmRemark,
      loadingRealize,
      loadingGet,
      canSchedule,
      handleBack,
      changedIndexes,
      showRemark,
      showNuOrder,
      openModalConfirmOtherClass,
      setShowRemark,
      setRemarkPast,
      remarkPast,
      handleDateRemark,
    }
  }, [
    dataTrackSchedule,
    dates,
    setDates,
    datesFormatted,
    projectType,
    validateMoment,
    disabledDate,
    loadingRequestSchedule,
    actionSchedule,
    confirmModal,
    handleCloseConfirm,
    openModalSucess,
    confirmModalDesengage,
    handleCloseConfirmDesengage,
    handleDesengageTrail,
    handleCloseSuccess,
    handleConfirm,
    actionDesengage,
    loadingDesengage,
    canDesengage,
    handleDateChange,
    handleDateChangeSchedule,
    classInfo,
    title,
    loadingUpdate,
    handleLeave,
    setOpenModalEtapa,
    openModalEtapa,
    handleDesengageEtapa,
    handleCloseDesengageEtapa,
    openModalRealize,
    handleRealize,
    handleCloseRealize,
    handleCloseConfirmOtherClass,
    dateRealize,
    handleConfirmRealize,
    handleConfirmRemark,
    loadingRealize,
    loadingGet,
    canSchedule,
    handleBack,
    changedIndexes,
    showRemark,
    showNuOrder,
    openModalConfirmOtherClass,
    setShowRemark,
    setRemarkPast,
    remarkPast,
    handleDateRemark,
  ])

  return (
    <ScheduleTrailPageContext.Provider value={scheduleTrailPageProviderValues}>
      {children}
    </ScheduleTrailPageContext.Provider>
  )
}

const useScheduleTrailPageContext = () => useContext(ScheduleTrailPageContext)

export { ScheduleTrailProvider, useScheduleTrailPageContext }

import { useRef, useState } from 'react'

import { AppBarComponent, ContainerComponent } from '~/components'

import { StepAccordion } from '~/containers/private/Fund1/Planning'
import {
  FixedEvidencesComponent,
  HeaderPlanning,
  ProjectGeneralOrientation,
  SkeletonLoading,
  VariableEvidencesComponent,
} from '~/containers/private/Fund2/Planning'

import { useProjectPlanningPageContext } from '~/pages/private/Fund2/Planning/context'

import * as S from './styles'

export const ProjectPlanningView = () => {
  const { projectMomentStage, isLoading, titleNuOrder, fixedEvidence, txTitleLesson, txOrientation, status } =
    useProjectPlanningPageContext()

  const overallStepRef = useRef<HTMLDivElement>(null)
  const [activedTab, setActivedTab] = useState(0)
  const hasVariableEvidence = false
  const firstStep = projectMomentStage?.[0]?.projectStage
  return (
    <>
      <AppBarComponent />

      <ContainerComponent>
        {isLoading ? (
          <SkeletonLoading />
        ) : (
          <>
            {projectMomentStage && (
              <HeaderPlanning
                status={status}
                txNameLesson={String(titleNuOrder)}
                activedTab={activedTab}
                stages={projectMomentStage}
                txTitleLesson={txTitleLesson}
                txOrientation={txOrientation}
              />
            )}
            <S.Container>
              <S.FixedEvidencesContainer>
                <FixedEvidencesComponent fixedEvidence={fixedEvidence} />
              </S.FixedEvidencesContainer>

              {(hasVariableEvidence?.length ?? 0) > 0 && (
                <S.VariableEvidencesContainer>
                  <VariableEvidencesComponent projectMomentStage={projectMomentStage} />
                </S.VariableEvidencesContainer>
              )}

              {txTitleLesson && txTitleLesson.length > 0 && (
                <ProjectGeneralOrientation
                  title={txOrientation}
                  accordionRef={overallStepRef}
                  key={'GeneralOrientation'}
                />
              )}

              {firstStep?.txDescription && (
                <StepAccordion
                  key={'generalStep'}
                  nonCollapsible
                  title="Orientações Gerais"
                  txGuidance={firstStep?.txDescription}
                  type="generalStep"
                />
              )}
            </S.Container>
          </>
        )}
      </ContainerComponent>
    </>
  )
}

import React from 'react'

import { UilArrowDown } from '@iconscout/react-unicons'

import { DividerComponent } from '~/components'

import CardCarousel from '../Carrousell/CardCarousel'
import { CustomHtmlProps, OrientationItem, Props } from './StepAccordion.interfaces'
import * as S from './StepAccordion.styles'

const CustomHtml: React.FC<CustomHtmlProps> = ({ htmlContent }) => {
  const modifiedHtml = htmlContent?.replace(/color: rgb\(\d+, \d+, \d+\)/g, 'color: black')
  return <S.AccordionText dangerouslySetInnerHTML={{ __html: modifiedHtml }} />
}

export const StepAccordion: React.FC<Props> = ({
  title,
  txGuidance,
  orientationItems,
  type,
  key,
  accordionRef,
  expaded = true,
  onToggle,
  nonCollapsible = false,
}) => (
  <S.AccordionContainer
    expanded={nonCollapsible || expaded}
    onChange={nonCollapsible ? undefined : onToggle}
    ref={accordionRef}
  >
    {!nonCollapsible && (
      <S.AccordionHeader
        expandIcon={
          <S.CircleIcon>
            <UilArrowDown />
          </S.CircleIcon>
        }
      >
        <S.AccordionTitle type={type}>{title}</S.AccordionTitle>
      </S.AccordionHeader>
    )}
    {nonCollapsible && (
      <S.StepOrientationContainerTitle>
        <S.AccordionTitlenoNCollapsible type={type}>{title}</S.AccordionTitlenoNCollapsible>
      </S.StepOrientationContainerTitle>
    )}
    {txGuidance ? (
      <S.StepOrientationContainerTitle>
        <CustomHtml htmlContent={String(txGuidance)} />
        <DividerComponent className="padded" />
        {orientationItems?.[0]?.lessonActivitySupportReference &&
          orientationItems[0].lessonActivitySupportReference.length > 0 && (
            <S.ReferenceContainer>
              <S.AccordionTitle type={type} className="reference">
                Referências de Apoio
              </S.AccordionTitle>
              <CardCarousel key={key} items={orientationItems?.[0].lessonActivitySupportReference} type={type} />
            </S.ReferenceContainer>
          )}
      </S.StepOrientationContainerTitle>
    ) : (
      orientationItems.map((item: OrientationItem, index) => (
        <React.Fragment key={index}>
          <S.StepOrientationContainerTitle>
            <CustomHtml htmlContent={String(item.txOrientationCode)} />
            {item?.lessonActivitySupportReference && item.lessonActivitySupportReference.length > 0 && (
              <S.ReferenceContainer>
                <DividerComponent className="padded" />
                <S.AccordionTitle type={type} className="reference">
                  Referências de Apoio
                </S.AccordionTitle>
                <CardCarousel key={index} items={item.lessonActivitySupportReference} type={type} />
              </S.ReferenceContainer>
            )}
          </S.StepOrientationContainerTitle>
        </React.Fragment>
      ))
    )}
  </S.AccordionContainer>
)

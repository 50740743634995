import { useState } from 'react'

import { DropdownSkillComponent } from '~/components'

import * as S from './FixedEvidences.styles'

interface IFixedEvidenceProps {
  openIndex: number | null
  onToggle: (index: number) => void
  showOfflineMode?: boolean
  fixedEvidence: Array<{
    txAbility: string
    txEvidence: string
    txIconSkill: string
    txIconCompetency: string
    txCompetency: string
  }>
}

interface IFixedEvidenceComponentProps {
  isOffline?: boolean
  fixedEvidence: Array<{
    txAbility: string
    txEvidence: string
    txIconSkill: string
    txIconCompetency: string
    txCompetency: string
  }>
}

const FixedEvidence = ({ fixedEvidence, openIndex, onToggle, showOfflineMode }: IFixedEvidenceProps) => {
  return (
    <S.ContainerEvidence>
      {fixedEvidence?.map((evidence, index) => (
        <DropdownSkillComponent
          key={index}
          isOpen={openIndex === index}
          onToggle={() => onToggle(index)}
          txSkillName={showOfflineMode ? evidence.txAbility : evidence?.ability?.txName || evidence?.txAbilityName}
          iconSkill={
            showOfflineMode ? evidence.txIconSkill : evidence?.ability?.txImagePath || evidence?.txAbilityImagePath
          }
          iconCompetency={
            showOfflineMode
              ? evidence.txIconCompetency
              : evidence?.ability?.competence?.txImagePath || evidence?.txCompetenceImagePath
          }
          txCompetency={
            showOfflineMode
              ? evidence.txCompetency
              : evidence?.ability?.competence?.txName || evidence?.txCompetenceName
          }
        />
      ))}
    </S.ContainerEvidence>
  )
}

export const FixedEvidencesComponent = ({ fixedEvidence, isOffline }: IFixedEvidenceComponentProps) => {
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null)

  const handleDropdownToggle = (index: number) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  return (
    <S.Container>
      <S.Title>ASETs da Aula</S.Title>
      <FixedEvidence
        openIndex={openDropdownIndex}
        onToggle={handleDropdownToggle}
        fixedEvidence={fixedEvidence}
        showOfflineMode={isOffline}
      />
    </S.Container>
  )
}
